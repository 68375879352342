import React from "react"
import {
    ProductHeroSection, ProductSubheadingSection,
} from "@/layout"

const DocsPage: React.FC = () => {
    return (
        <div className={'container-docs'}>
            <ProductHeroSection/>
            <ProductSubheadingSection/>
        </div>
    )
}

export default DocsPage
